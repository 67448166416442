import React, { useEffect, useRef } from "react";
import { useAppContext } from "../contexts/AppContext";
import { Button, Link } from "@nextui-org/react";

/**
 * *oh god the terrible code*
 *
 * not sure of another clean way to do this
 *
 * This is used to show details about the event, immediately on page load
 *
 * used by the canvas preview page to get people hyped up for the event (<7 days before)
 */
export const EventInfoOverlay = ({ children }: React.PropsWithChildren) => {
  const { setInfoSidebar, setSettingsSidebar } = useAppContext();

  return (
    <div
      className="bg-black text-white p-4 fixed top-0 left-0 w-full z-[9999] flex flex-row"
      style={{
        pointerEvents: "initial",
      }}
    >
      <div>
        <h1 className="text-4xl font-bold">Canvas 2024</h1>
        <h2 className="text-3xl">Ended July 16th @ 4am UTC</h2>
      </div>

      <div className="flex-grow" />

      <div>
        <Button as={Link} href="https://sc07.shop" color="primary">
          Shop (poster prints!)
        </Button>
        <Button onPress={() => setInfoSidebar(true)}>Info</Button>
        <Button onPress={() => setSettingsSidebar(true)}>Settings</Button>
      </div>
    </div>
  );
};
